.ReleaseCard__releaseCard--3-LYE {
  display: block;
  padding: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
  position: relative;
  background-color: #ffffff;
  border-radius: 3px;
  transition: box-shadow 0.5s, transform 0.5s;
  overflow: hidden;
  outline: none;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy {
  box-shadow: none;
  background-color: #fafafa;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy:hover,
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy:active {
  box-shadow: none;
  transform: none;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy:hover .ReleaseCard__coverBg--1soow,
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy:active .ReleaseCard__coverBg--1soow {
  opacity: 0;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy:active {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy .ReleaseCard__info--34scj {
  padding: 5px 10px 10px;
  background-color: #fafafa;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy .ReleaseCard__info--34scj .ReleaseCard__bottomInfo--2s4Mb {
  align-items: flex-start;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy .ReleaseCard__info--34scj .ReleaseCard__bottomInfo--2s4Mb .ReleaseCard__bottomInfoContent--1PxPh .ReleaseCard__year--2ca10 {
  line-height: 14px;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy .ReleaseCard__price--GC7Fo {
  height: 31px;
  line-height: 31px;
  min-width: 51px;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy .ReleaseCard__releaseTitle--1AFXc {
  font-size: 14px;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy .ReleaseCard__releaseTitle--1AFXc a {
  width: 100%;
}
.ReleaseCard__releaseCard--3-LYE.ReleaseCard__light--1QyTy h4 {
  font-weight: bold;
  color: #f26118;
}
.ReleaseCard__releaseCard--3-LYE:hover,
.ReleaseCard__releaseCard--3-LYE:active {
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.3);
  transform: translate(0, -2px);
}
.ReleaseCard__releaseCard--3-LYE:hover .ReleaseCard__coverBg--1soow,
.ReleaseCard__releaseCard--3-LYE:active .ReleaseCard__coverBg--1soow {
  opacity: 0.7;
}
.ReleaseCard__releaseCard--3-LYE:hover .ReleaseCard__releaseTitle--1AFXc a,
.ReleaseCard__releaseCard--3-LYE:active .ReleaseCard__releaseTitle--1AFXc a {
  color: #f26118;
}
.ReleaseCard__coverLink--2Txr6 {
  width: 100%;
  position: relative;
  display: block;
  line-height: 0;
  padding-top: 100%;
}
.ReleaseCard__coverLink--2Txr6 .ReleaseCard__coverBg--1soow {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.5s;
  background-image: linear-gradient(314deg, #ff5147, #ee8514);
}
.ReleaseCard__cover--2K6El {
  width: 100%;
  height: auto;
  max-width: 100%;
  position: absolute;
  top: 0;
}
.ReleaseCard__info--34scj {
  margin-bottom: 5px;
  padding: 10px;
  height: 120px;
  display: flex;
  flex-direction: column;
}
.ReleaseCard__info--34scj.ReleaseCard__noArtist--w17rd {
  height: 90px;
}
.ReleaseCard__info--34scj .ReleaseCard__releaseTitle--1AFXc {
  color: #4a4a4a;
  max-height: 36px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
  align-items: center;
}
.ReleaseCard__info--34scj .ReleaseCard__releaseTitle--1AFXc a {
  color: #4a4a4a;
  width: 100%;
}
.ReleaseCard__info--34scj .ReleaseCard__artistName--aAEwM {
  display: inline-block;
  color: #4a4a4a;
  width: 100%;
}
.ReleaseCard__info--34scj .ReleaseCard__price--GC7Fo {
  display: block;
  min-width: 60px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  background-color: #30b8e9;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  font-weight: 300;
  margin-left: auto;
  padding: 0 5px;
}
.ReleaseCard__info--34scj .ReleaseCard__price--GC7Fo strong {
  font-weight: 700;
}
.ReleaseCard__info--34scj .ReleaseCard__bottomInfo--2s4Mb {
  display: flex;
  align-items: flex-end;
  margin-top: auto;
}
.ReleaseCard__info--34scj .ReleaseCard__bottomInfo--2s4Mb .ReleaseCard__bottomInfoContent--1PxPh {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
  margin-right: 5px;
  flex-wrap: nowrap;
  overflow: hidden;
}
.ReleaseCard__info--34scj .ReleaseCard__bottomInfo--2s4Mb .ReleaseCard__bottomInfoContent--1PxPh .ReleaseCard__sep--34-a- {
  width: 4px;
  height: 4px;
  background-color: rgba(74, 74, 74, 0.4);
  border-radius: 4px;
  flex: 0 0 auto;
  display: block;
  margin: 0 7px;
}
@media only screen and (max-width: 577px) {
  .ReleaseCard__info--34scj .ReleaseCard__bottomInfo--2s4Mb .ReleaseCard__bottomInfoContent--1PxPh .ReleaseCard__sep--34-a- {
    display: none;
  }
}
.ReleaseCard__info--34scj .ReleaseCard__bottomInfo--2s4Mb .ReleaseCard__bottomInfoContent--1PxPh .ReleaseCard__genre--EPbvD {
  opacity: 0.4;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 577px) {
  .ReleaseCard__info--34scj .ReleaseCard__bottomInfo--2s4Mb .ReleaseCard__bottomInfoContent--1PxPh .ReleaseCard__genre--EPbvD {
    display: none;
  }
}
.ReleaseCard__info--34scj .ReleaseCard__bottomInfo--2s4Mb .ReleaseCard__bottomInfoContent--1PxPh .ReleaseCard__year--2ca10 {
  color: #4a4a4a;
  opacity: 0.4;
  font-size: 14px;
}
.ReleaseCard__actions--3p_pA {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.ReleaseCard__priceWrapper--2tjYX {
  text-align: right;
}
