.RecommendedReleases__hidden--1PpeY {
  display: none;
}
.RecommendedReleases__releaseListContainer--3G7-m {
  margin: 0 -5px;
}
@media (min-width: 576px) {
  .RecommendedReleases__releaseListContainer--3G7-m {
    margin: 0 -8px;
  }
}
@media (min-width: 768px) {
  .RecommendedReleases__releaseListContainer--3G7-m {
    margin: 0 -10px;
  }
}
.RecommendedReleases__releaseListContainer--3G7-m > div[class*="ant-col"] {
  padding: 0 5px;
}
@media (min-width: 576px) {
  .RecommendedReleases__releaseListContainer--3G7-m > div[class*="ant-col"] {
    padding: 0 8px;
  }
}
@media (min-width: 768px) {
  .RecommendedReleases__releaseListContainer--3G7-m > div[class*="ant-col"] {
    padding: 0 10px;
  }
}
