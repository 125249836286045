.Pagination__container--1kW7l {
  width: 100%;
  margin-top: 15px;
  align-items: center;
  flex-direction: column;
}
.Pagination__pagination--1fKX2 {
  align-self: center;
  margin: 34px 0;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 577px) {
  .Pagination__pagination--1fKX2 {
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
  }
}
@media only screen and (max-width: 577px) {
  .Pagination__pagination--1fKX2:after {
    display: none;
  }
}
.Pagination__pagination--1fKX2 .ant-pagination-item {
  color: #30b8e9;
  width: auto;
  height: 30px;
  min-width: 30px;
  border: 0;
  border-radius: 3px;
  background-color: #fafafa;
  margin-right: 22px;
}
.Pagination__pagination--1fKX2 .ant-pagination-item a {
  color: #30b8e9;
  padding: 0 6px;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
}
.Pagination__pagination--1fKX2 .ant-pagination-item a:hover {
  color: #30b8e9;
}
@media only screen and (max-width: 577px) {
  .Pagination__pagination--1fKX2 .ant-pagination-item {
    margin-right: 0;
  }
}
@media only screen and (min-width: 578px) and (max-width: 992px) {
  .Pagination__pagination--1fKX2 .ant-pagination-item {
    margin-right: 16px;
  }
}
.Pagination__pagination--1fKX2 .ant-pagination-prev {
  margin-right: 45px;
}
@media only screen and (max-width: 577px) {
  .Pagination__pagination--1fKX2 .ant-pagination-prev {
    margin-right: 0;
  }
}
@media only screen and (min-width: 578px) and (max-width: 992px) {
  .Pagination__pagination--1fKX2 .ant-pagination-prev {
    margin-right: 24px;
  }
}
.Pagination__pagination--1fKX2 .ant-pagination-next {
  margin-left: 23px;
}
@media only screen and (max-width: 577px) {
  .Pagination__pagination--1fKX2 .ant-pagination-next {
    margin-left: 0;
  }
}
@media only screen and (min-width: 578px) and (max-width: 992px) {
  .Pagination__pagination--1fKX2 .ant-pagination-next {
    margin-left: 8px;
  }
}
.Pagination__pagination--1fKX2 .ant-pagination-next,
.Pagination__pagination--1fKX2 .ant-pagination-prev,
.Pagination__pagination--1fKX2 .ant-pagination-jump-prev,
.Pagination__pagination--1fKX2 .ant-pagination-jump-next {
  height: 30px;
  width: 30px;
  min-width: 30px;
}
@media only screen and (max-width: 577px) {
  .Pagination__pagination--1fKX2 .ant-pagination-next,
  .Pagination__pagination--1fKX2 .ant-pagination-prev,
  .Pagination__pagination--1fKX2 .ant-pagination-jump-prev,
  .Pagination__pagination--1fKX2 .ant-pagination-jump-next {
    margin-right: 0;
  }
}
.Pagination__pagination--1fKX2 .ant-pagination-next a,
.Pagination__pagination--1fKX2 .ant-pagination-prev a {
  display: flex;
  line-height: 30px;
  height: 30px;
  align-items: center;
  width: 30px;
  justify-content: center;
}
.Pagination__pagination--1fKX2 .ant-pagination-item-active {
  background-color: #f26118;
  color: #ffffff;
}
.Pagination__pagination--1fKX2 .ant-pagination-item-active a {
  color: #ffffff;
}
.Pagination__pagination--1fKX2 svg {
  fill: #30b8e9;
}
.Pagination__pagination--1fKX2 .ant-pagination-disabled svg {
  fill: #d7d7d7;
}
.Pagination__loadMore--2F7Z8 {
  text-align: center;
}
.Pagination__loadMore--2F7Z8 .ant-btn {
  text-transform: uppercase;
  border-radius: 3px;
  background-color: #fafafa;
  width: 100%;
  color: #4a4a4a;
  font-size: 13px;
  max-width: 300px;
  border: none;
  height: 36px;
  line-height: 36px;
}
.Pagination__loadMore--2F7Z8 .ant-btn:hover {
  background-color: #eaeaea;
  color: #999;
}
